import { graphql, Link, useStaticQuery } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

interface MenuItemProps {
  title: string;
  link: string;
  isAnchor?: boolean;
  subMenu?: MenuItemProps[];
}

function MenuItem({ title, link, isAnchor, subMenu }: MenuItemProps) {
  const handleClick = () => {
    const focusedEl = document.activeElement;
    if (focusedEl) {
      (focusedEl as HTMLElement).blur();
    }
  };
  const handleTouch = (e: React.SyntheticEvent) => {
    const { parentElement } = e.currentTarget;

    if (!subMenu || !parentElement) {
      return;
    }

    if (parentElement.classList.contains('menu-item--active')) {
      parentElement.classList.remove('menu-item--active');
    } else {
      e.preventDefault();
      parentElement.classList.add('menu-item--active');
    }
  };

  const handleHover = (e: React.MouseEvent) => {
    const { parentElement } = e.currentTarget;

    if (!subMenu || !parentElement) {
      return;
    }

    parentElement.classList.remove('menu-item--active');
  };

  return (
    <li className={`menu-item ${subMenu ? 'menu-item--has-children' : ''}`}>
      {(isAnchor && (
        <AnchorLink
          to={link}
          onAnchorLinkClick={handleClick}
          gatsbyLinkProps={{
            onTouchEnd: handleTouch,
            onMouseEnter: handleHover,
          }}
        >
          {title}
        </AnchorLink>
      )) || (
        <Link
          to={link}
          onClick={handleClick}
          onTouchEnd={handleTouch}
          onMouseEnter={handleHover}
        >
          {title}
        </Link>
      )}
      {subMenu && (
        <ul className="sub-menu">
          {subMenu.map((subMenuItem) => (
            <MenuItem
              key={`${subMenuItem.title}-${subMenuItem.link}`}
              {...subMenuItem}
            />
          ))}
        </ul>
      )}
    </li>
  );
}

export default function Menu({
  className = '',
  showSubMenu = true,
}: {
  className?: string;
  showSubMenu?: boolean;
}) {
  const { allSitePage } = useStaticQuery<{
    allSitePage?: {
      nodes?: {
        id: string;
        path: string;
        pageContext?: { categoryName?: string };
      }[];
    };
  }>(graphql`
    query {
      allSitePage(filter: { path: { glob: "/news-press/*" } }) {
        nodes {
          id
          path
          pageContext
        }
      }
    }
  `);

  const newsPressSubmenu =
    allSitePage?.nodes?.length && allSitePage.nodes.length > 1
      ? allSitePage.nodes.map((node) => ({
          title: node.pageContext?.categoryName,
          link: node.path,
        }))
      : null;

  const menuData = [
    {
      title: 'Home',
      link: '/',
    },
    {
      title: 'Videos',
      link: '/videos',
    },
    {
      title: 'News',
      link: '/news-press',
      subMenu: newsPressSubmenu,
    },
    {
      title: 'About the CCCU',
      link: '/#about',
      isAnchor: true,
      subMenu: [
        {
          title: 'CCCU Members',
          link: '/#members',
          isAnchor: true,
        },
      ],
    },
    {
      title: 'Story Sharing',
      link: '/share',
    },
  ];

  const menu = showSubMenu
    ? menuData
    : menuData.map((menuItem) => {
        const { subMenu, ...rest } = menuItem;
        return rest;
      });
  return (
    <nav className={`menu ${className}`}>
      <ul className="menu__inner">
        {menu.map((menuItem) => (
          <MenuItem key={`${menuItem.title}-${menuItem.link}`} {...menuItem} />
        ))}
      </ul>
    </nav>
  );
}
