import { Link } from 'gatsby';
import Colophon from './Colophon';
import Logo from '../images/logo.svg';
import Menu from './Menu';
import SocialList from './SocialList';

function FooterLinks() {
  return (
    <div className="footer__links">
      <ul>
        <li>
          <Link to="/">Terms of Use</Link>
        </li>
        <li>
          <Link to="/">Privacy Policy</Link>
        </li>
        <li>
          <Link to="/">Cookie Policy</Link>
        </li>
      </ul>
    </div>
  );
}

export default function Footer() {
  return (
    <footer className="footer container">
      <div className="footer__inner">
        <div className="footer__left hide-xs show-md">
          {/* <FooterLinks /> */}
        </div>
        <div className="footer__right ">
          <div className="footer__right__bg hide-xs show-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 188 167"
            >
              <path
                fill="#B6B09E"
                fillOpacity=".2"
                d="M45 166.154A44.998 44.998 0 0 1 .865 112.375 45 45 0 0 1 90 121.154a45.04 45.04 0 0 1-45 45Zm0-79.239a34.24 34.24 0 1 0 34.24 34.239A34.267 34.267 0 0 0 45 86.915ZM181.349 36.916h-29.698V6.884a6.772 6.772 0 0 0-1.948-4.759A6.611 6.611 0 0 0 145 .155a6.611 6.611 0 0 0-4.703 1.97 6.772 6.772 0 0 0-1.948 4.759v30.032h-29.698c-1.764 0-3.456.709-4.703 1.97a6.772 6.772 0 0 0-1.948 4.76c0 1.784.701 3.496 1.948 4.758a6.611 6.611 0 0 0 4.703 1.971h29.698v30.05c0 1.785.701 3.496 1.948 4.758A6.611 6.611 0 0 0 145 87.154a6.611 6.611 0 0 0 4.703-1.97 6.772 6.772 0 0 0 1.948-4.76V50.376h29.698a6.611 6.611 0 0 0 4.703-1.971A6.771 6.771 0 0 0 188 43.645a6.772 6.772 0 0 0-1.948-4.758 6.612 6.612 0 0 0-4.703-1.971Z"
              />
            </svg>
          </div>
          <div className="footer__right__inner push-right-sm">
            <div className="footer__logo">
              <div className="footer__logo__img">
                <img
                  src={Logo}
                  alt="CCCU"
                  width={94}
                  height={25}
                  loading="lazy"
                />
              </div>
              <div className="footer__logo__text">
                <h2 className="h5 footer__logo__text__title">
                  Council for Christian Colleges & Universities
                </h2>
                <address className="footer__logo__text__address">
                  20 M Street SE, Suite 350 , Washington, D.C. 20003
                </address>
              </div>
            </div>
            <div className="footer__menu">
              <div className="footer__menu__nav">
                <Menu showSubMenu={false} />
              </div>
              <div className="footer__menu__social">
                <SocialList />
              </div>
            </div>
            {/* <div className="hide-md">
              <FooterLinks />
            </div> */}
          </div>
        </div>
        {/* <Colophon /> */}
      </div>
    </footer>
  );
}
