import { graphql, useStaticQuery } from 'gatsby';

import {
  RiTwitterFill,
  RiLinkedinFill,
  RiFacebookFill,
  RiYoutubeFill,
  RiInstagramFill,
} from 'react-icons/ri';

export default function SocialList() {
  const data = useStaticQuery(graphql`
    query {
      wp {
        themeGeneralSettings {
          socialMedia {
            socialMedia {
              facebook
              instagram
              twitter
              youtube
              linkedin
            }
          }
        }
      }
    }
  `);

  const socialMedia = data?.wp?.themeGeneralSettings?.socialMedia?.socialMedia;

  if (!socialMedia) {
    return null;
  }

  const { facebook, twitter, linkedin, youtube, instagram } = socialMedia;

  return (
    <div className="social-list">
      <ul>
        {facebook && (
          <li>
            <a href={facebook} target="_blank" rel="noopener noreferrer">
              <span className="visually-hidden">Facebook</span>
              <RiFacebookFill />
            </a>
          </li>
        )}
        {twitter && (
          <li>
            <a href={twitter} target="_blank" rel="noopener noreferrer">
              <span className="visually-hidden">Twitter</span>
              <RiTwitterFill />
            </a>
          </li>
        )}
        {linkedin && (
          <li>
            <a href={linkedin} target="_blank" rel="noopener noreferrer">
              <span className="visually-hidden">LinkedIn</span>
              <RiLinkedinFill />
            </a>
          </li>
        )}
        {youtube && (
          <li>
            <a href={youtube} target="_blank" rel="noopener noreferrer">
              <span className="visually-hidden">Youtube</span>
              <RiYoutubeFill />
            </a>
          </li>
        )}
        {instagram && (
          <li>
            <a href={instagram} target="_blank" rel="noopener noreferrer">
              <span className="visually-hidden">Instagram</span>
              <RiInstagramFill />
            </a>
          </li>
        )}
      </ul>
    </div>
  );
}
