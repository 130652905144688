import { CSSProperties, MouseEventHandler } from 'react';

interface Props {
  isOpen: boolean;
  menuClicked: MouseEventHandler<HTMLButtonElement>;
  width?: number;
  height?: number;
  strokeWidth?: number;
  rotate?: number;
  color?: string;
  borderRadius?: number;
  animationDuration?: number;
  text?: string;
  closeText?: string;
  className?: string;
}

interface stylesProps {
  button: CSSProperties;
  container: CSSProperties;
  lineBase: CSSProperties;
  firstLine: CSSProperties;
  secondLine: CSSProperties;
  thirdLine: CSSProperties;
  text: CSSProperties;
}

export default function HamburgerMenu({
  isOpen,
  menuClicked,
  width = 36,
  height = 30,
  strokeWidth = 2,
  rotate,
  color,
  borderRadius,
  animationDuration = 0.4,
  text,
  closeText,
  className,
}: Props) {
  const cssWidth: CSSProperties['width'] = `${width}px`;
  const cssHeight: CSSProperties['height'] = `${height}px`;
  const halfHeight: string = `${height / 2}px`;
  const halfStrokeWidth = `-${strokeWidth / 2}px`;
  const buttonCloseText = closeText || text;
  const buttonText = isOpen ? buttonCloseText : text;

  function getTransformValue(
    thisIsOpen: boolean,
    thisDefaultPos: number | string,
    thisRotateVal: number
  ) {
    return `translate3d(0,${
      thisIsOpen ? halfHeight : thisDefaultPos
    },0) rotate(${thisIsOpen ? `${thisRotateVal}deg` : '0'})`;
  }

  const styles: stylesProps = {
    button: {
      // Revert default <button> styles
      display: 'flex',
      alignItems: 'center',
      background: 'transparent',
      padding: 0,
      border: 0,
    },
    container: {
      width: cssWidth,
      height: cssHeight,
      position: 'relative',
      transform: `rotate(${rotate || 0}deg)`,
    },
    lineBase: {
      display: 'block',
      height: `${strokeWidth}px`,
      width: '100%',
      background: color || '#000',
      transitionTimingFunction: 'ease',
      transitionDuration: `${animationDuration}s`,
      borderRadius: `${borderRadius || 0}px`,
      transformOrigin: 'center',
      position: 'absolute',
    },
    firstLine: {
      transform: getTransformValue(isOpen, 0, 45),
      marginTop: halfStrokeWidth,
    },
    secondLine: {
      transitionTimingFunction: 'ease-out',
      transitionDuration: `${animationDuration / 4}s`,
      opacity: isOpen ? '0' : '1',
      top: halfHeight,
      marginTop: halfStrokeWidth,
    },
    thirdLine: {
      transform: getTransformValue(isOpen, cssHeight, -45),
      marginTop: halfStrokeWidth,
    },
    text: {
      marginLeft: '0.5em',
    },
  };

  return (
    <button
      type="button"
      style={styles.button}
      className={className}
      onClick={menuClicked}
    >
      <div style={styles.container}>
        <span style={{ ...styles.lineBase, ...styles.firstLine }} />
        <span style={{ ...styles.lineBase, ...styles.secondLine }} />
        <span style={{ ...styles.lineBase, ...styles.thirdLine }} />
      </div>
      {buttonText && <span style={styles.text}>{buttonText}</span>}
    </button>
  );
}
