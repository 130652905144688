import { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import HamburgerMenu from './HamburgerMenu';
import Logo from '../images/logo.svg';
import { useWindowSize } from '../hooks/useWindowSize';
import useOnClickOutside from '../hooks/useOnClickOutside';
import SocialList from './SocialList';
import Menu from './Menu';

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const mobileMenuWrapperRef = useRef<HTMLDivElement>(null);

  const { width } = useWindowSize(); // Window Width

  useEffect(() => {
    if (menuOpen) {
      document.documentElement.classList.add('menu-open');
    } else {
      document.documentElement.classList.remove('menu-open');
    }

    if (menuOpen && width && width > 767) {
      setMenuOpen(false);
    }
  }, [menuOpen, width]);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as Element | undefined;
      if (
        target &&
        mobileMenuWrapperRef.current?.contains(target) &&
        target.hasAttribute('href')
      ) {
        setMenuOpen(false);
      }
    };
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [menuOpen]);

  useOnClickOutside(mobileMenuWrapperRef, () => setMenuOpen(false));

  return (
    <header ref={mobileMenuWrapperRef} className="header container">
      <div className="header__inner push-left push-right push-right-sm-0">
        <div className="header__logo">
          <Link to="/" onClick={() => setMenuOpen(false)}>
            <img src={Logo} alt="CCCU" width={94} height={25} loading="eager" />
          </Link>
          <div className="header__hamburger-menu hide-sm">
            <HamburgerMenu
              isOpen={menuOpen}
              menuClicked={() => setMenuOpen(!menuOpen)}
              width={24}
              height={15}
              strokeWidth={2}
              rotate={0}
              color="var(--white)"
              borderRadius={0}
              // animationDuration={0.5}
            />
          </div>
          <span className="header__logo__text">
            Council for Christian Colleges & Universities
          </span>
        </div>
        <div className="hide-xs show-sm--flex">
          <div className="header__social">
            <SocialList />
          </div>
        </div>
        <div className="header__menu hide-xs show-sm--flex">
          <Menu className="header__menu__nav" />
        </div>
      </div>
      <div className="header__mobile-menu">
        <div className="header__mobile-menu__inner">
          <Menu className="header__menu__nav" />
          <div className="header__social">
            <SocialList />
          </div>
        </div>
      </div>
    </header>
  );
}
